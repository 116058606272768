import getDocumentSignatures from "../services/getDocumentSignatures";

const useGetDocumentSignatures = () => {
  return {
    getSignatures: async (documentId?: string) =>
      getDocumentSignatures(documentId),
  };
};

export default useGetDocumentSignatures;
