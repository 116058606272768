import { fetcher } from "../../../utils/fetcher";
import { documentSignatureType } from "../types/documentSignatureType";

async function getDocumentSignatures(documentId?: string) {
  return await fetcher.get<documentSignatureType[]>(
    `pdf/signers/${documentId}`
  );
}

export default getDocumentSignatures;
