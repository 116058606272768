import { Route, Routes } from "react-router-dom";
import Document from "../features/document";
import Layout from "../features/layout";

const AppRoutes: React.FC = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/document/:documentId?" element={<Document />} />
        <Route path="/" element={<NotFound />} />
      </Routes>
    </Layout>
  );
};

const NotFound: React.FC = () => {
  return null;
};

export default AppRoutes;
