import { ThemeProvider } from "@suit-ui/react";
import { ReactNode } from "react";
import ConfigsWrapper from "configs/ConfigsWrapper";

interface AppProviderProps {
  children?: ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  return (
    <ConfigsWrapper>
      <ThemeProvider>{children}</ThemeProvider>
    </ConfigsWrapper>
  );
};

export default AppProvider;
