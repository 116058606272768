import { Check, Clear, Error, FolderOff, Search } from "@suit-ui/icons";
import { Box, Button, Icon, Tooltip } from "@suit-ui/react";
import { AxiosError } from "axios";
import Accordion from "components/Accordion";
import PlaceholderLoader from "components/PlaceholderLoader";
import useGetDocumentSignatures from "features/document/hooks/useGetDocumentSignatures";
import { documentSignatureType } from "features/document/types/documentSignatureType";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface DocumentSignaturesProps {
  documentId?: string;
  updateDocumentId: () => void;
}

const DocumentSignatures: React.FC<DocumentSignaturesProps> = ({
  documentId,
  updateDocumentId,
}) => {
  const { t } = useTranslation();
  const { getSignatures } = useGetDocumentSignatures();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [signatures, setSignatures] = useState<documentSignatureType[]>([]);

  const getDocumentSignatures = async () => {
    if (!documentId) {
      return;
    }

    try {
      setError(null);
      setIsLoading(true);
      const {
        data,
        status,
      }: { data: documentSignatureType[]; status: number } =
        (await getSignatures(documentId)) as any;
      setIsLoading(false);

      if (status === 200) {
        setSignatures(data);
        return;
      }
    } catch (e: unknown) {
      setIsLoading(false);
      if (e instanceof AxiosError) {
        const { status } = (e as any).response;

        if (status === 404) {
          setError(t("document_not_found"));
          return;
        }
      } else {
        setError(t("unexpected_error"));
      }
    }
  };

  useEffect(() => {
    getDocumentSignatures();

    // eslint-disable-next-line
  }, [documentId]);

  return (
    <Box className="w-full flex flex-col border border-neutral-200 rounded-sm shadow-sm p-4">
      <Box className="mb-2 flex items-center">
        <Box className="text-sm font-bold sm:text-md" as="span">
          {t("signatures_list_title")}
        </Box>
        <Box as="span" className="text-xs sm:text-md font-light ml-2">
          {documentId}
        </Box>

        <Button
          disabled={isLoading}
          onClick={() => updateDocumentId()}
          className="ml-auto"
          size="xs"
        >
          {t("update_signatures")}
        </Button>
      </Box>
      <Box className="w-full">
        {!documentId && (
          <Box className="h-32 flex flex-col justify-center items-center">
            <Icon as={Search} size="18" className="text-neutral-200" />
            <Box as="span" className="text-primary-900">
              {t("document_id_missing")}
            </Box>
          </Box>
        )}

        {error && (
          <Box className="h-32 flex flex-col justify-center items-center">
            <Icon as={Error} size="18" className="text-danger-400" />
            <Box as="span" className="text-primary-900">
              {error}
            </Box>
          </Box>
        )}
        {isLoading && (
          <>
            <PlaceholderLoader className="w-full h-12 rounded-sm mb-2" />
            <PlaceholderLoader className="w-full h-12 rounded-sm mb-2" />
            <PlaceholderLoader className="w-full h-12 rounded-sm mb-2" />
          </>
        )}

        {documentId && !error && !isLoading && (
          <>
            {signatures.length === 0 ? (
              <Box className="h-32 flex flex-col justify-center items-center">
                <Icon as={FolderOff} size="18" className="text-neutral-200" />
                <Box as="span" className="text-primary-900">
                  {t("no_signatures_found")}
                </Box>
              </Box>
            ) : (
              <Accordion>
                {signatures.map(({ name, email, valid, issuer }, idx) => (
                  <Accordion.Item
                    className="flex border border-neutral-200 rounded-sm px-4 py-2 items-center mb-2"
                    key={`${documentId}-signature-${idx}`}
                  >
                    <Accordion.Button>
                      <Box as="span" className="text-xs sm:text-sm">
                        {name}
                      </Box>

                      <Tooltip
                        label={
                          valid ? t("signature_valid") : t("signature_no_valid")
                        }
                      >
                        <Box
                          className={`${
                            valid ? "bg-success-200" : "bg-danger-200"
                          } flex p-1 rounded-full ml-auto`}
                        >
                          <Icon as={valid ? Check : Clear} size="5" />
                        </Box>
                      </Tooltip>
                    </Accordion.Button>

                    <Accordion.Panel className="flex flex-col">
                      <Box className="flex items-center mb-2">
                        <Box
                          as="span"
                          className="font-semibold text-xs sm:text-sm text-primary-900"
                        >
                          {t("email")}:
                        </Box>
                        <Box
                          as="span"
                          className="text-xs sm:text-sm font-light ml-2"
                        >
                          {email}
                        </Box>
                      </Box>
                      <Box className="flex items-center">
                        <Box
                          as="span"
                          className="font-semibold text-xs sm:text-sm text-primary-900"
                        >
                          {t("issuer")}:
                        </Box>
                        <Box
                          as="span"
                          className="text-xs sm:text-sm font-light ml-2"
                        >
                          {issuer}
                        </Box>
                      </Box>
                    </Accordion.Panel>
                  </Accordion.Item>
                ))}
              </Accordion>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default DocumentSignatures;
