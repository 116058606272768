import { ReactNode } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./i18n";

interface ConfigsWrapperProps {
  children?: ReactNode;
}

const ConfigsWrapper: React.FC<ConfigsWrapperProps> = ({ children }) => (
  <Router>{children}</Router>
);

export default ConfigsWrapper;
