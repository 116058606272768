import MotionBox from "components/MotionBox";
import { AnimatePresence } from "framer-motion";
import { Box, Icon } from "@suit-ui/react";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useId,
  useState,
} from "react";
import ChildrenType from "types/ChildrenType";
import { ChevronRight } from "@suit-ui/icons";

interface AccordionContextProps {
  expanded: false | string;
  setExpanded: (prop: false | string) => void;
}

interface AccordionItemContextProps {
  id: string;
  isOpen: boolean;
}

interface AccordionProps {
  children?: ReactNode;
  className?: string;
}

const AccordionContext = createContext<AccordionContextProps | undefined>(
  undefined
);

const AccordionItemContext = createContext<
  AccordionItemContextProps | undefined
>(undefined);

export const useAccordion = () =>
  useContext(AccordionContext) as AccordionContextProps;
export const useAccordionItem = () =>
  useContext(AccordionItemContext) as AccordionItemContextProps;

const Accordion = ({ children, className = "" }: AccordionProps) => {
  const [expanded, setExpanded] = useState<false | string>(false);

  return (
    <AccordionContext.Provider value={{ expanded, setExpanded }}>
      <Box className={className}>{children}</Box>
    </AccordionContext.Provider>
  );
};

interface AccordionItemProps extends ChildrenType {
  isExpanded?: boolean;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  children,
  isExpanded,
}) => {
  const { expanded, setExpanded } = useAccordion();
  const id = useId();
  const isOpen = id === expanded;

  useEffect(() => {
    if (isExpanded) {
      setExpanded(id);
    } else {
      setExpanded(false);
    }
  }, [id, setExpanded, isExpanded]);

  return (
    <AccordionItemContext.Provider value={{ id, isOpen }}>
      <MotionBox
        data-testid="AccordionItem"
        className={`border border-neutral-300 my-2 rounded-md bg-neutral-50 ${
          !isOpen ? "hover:border-primary-01" : ""
        }`}
      >
        {children}
      </MotionBox>
    </AccordionItemContext.Provider>
  );
};

interface AccordionButtonProps extends ChildrenType {
  isExpandable?: boolean;
  onClick?: () => void | undefined;
  ActionsMenu?: ReactNode | JSX.Element;
}

const AccordionButton: React.FC<AccordionButtonProps> = ({
  children,
  isExpandable = true,
  onClick,
  ActionsMenu,
}) => {
  const { setExpanded } = useAccordion();
  const { isOpen, id } = useAccordionItem();

  return (
    <Box className="flex py-3 px-2">
      <MotionBox
        data-testid="AccordionButton"
        initial={false}
        className={`${
          isExpandable ? "cursor-pointer" : ""
        } flex items-center flex-grow-1 flex-shrink-1 w-full`}
        onClick={() => {
          if (isExpandable) setExpanded(isOpen ? false : id);
          onClick?.();
        }}
      >
        <MotionBox
          key="AccordionButtonIcon"
          className="inline-flex mr-1"
          initial={false}
          animate={{ rotate: isOpen ? 90 : 0 }}
        >
          <Icon
            as={ChevronRight}
            className={`${
              isExpandable ? "text-primary-600" : "text-disabled-01"
            }`}
          />
        </MotionBox>
        {children}
      </MotionBox>
      {ActionsMenu}
    </Box>
  );
};

const AccordionPanel: React.FC<ChildrenType> = ({
  children,
  className = "",
}) => {
  const { isOpen } = useAccordionItem();

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <MotionBox
          className="overflow-hidden border-t border-neutral-300 rounded-b-md"
          key="AccordionPanel"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          <Box className={`p-4 ${className}`}>{children}</Box>
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

Accordion.Item = AccordionItem;
Accordion.Button = AccordionButton;
Accordion.Panel = AccordionPanel;

export default Accordion;
