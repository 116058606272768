import AppRoutes from "./routes";
import AppProvider from "./state/AppProvider";

function App() {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
