import { Box } from "@suit-ui/react";
import { ReactNode } from "react";

interface LayoutProps {
  children?: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return <Box className="min-h-screen">{children}</Box>;
};

export default Layout;
