import { Banner, Box, Button, Input } from "@suit-ui/react";
import { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { downloadFileFromBinary } from "../../../utils/downloadFileFromBinary";
import DocumentSignatures from "../components/DocumentSignatures";
import useDownloadDocument from "../hooks/useDownloadDocument";

const Document: React.FC = () => {
  const { t } = useTranslation();
  const { documentId: documentIdUrl } = useParams();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [documentId, setDocumentId] = useState<string | undefined>(
    documentIdUrl
  );
  const [currentDocumentId, setCurrentDocumentId] = useState<
    string | undefined
  >(documentIdUrl);
  const { download } = useDownloadDocument();

  const downLoadDocument = async () => {
    if (!documentId) {
      setError(t("document_id_missing"));
      return;
    }

    try {
      setError(null);
      setIsDownloading(true);
      const { status, data: blob, headers } = await download(documentId);
      setIsDownloading(false);

      const fileName = headers?.["content-disposition"]?.split("=")[1];

      if (status === 200) {
        downloadFileFromBinary(blob, fileName || t("no_name"));
        return;
      }
    } catch (e: unknown) {
      setIsDownloading(false);

      if (e instanceof AxiosError) {
        const { status } = (e as any).response;

        if (status === 404) {
          setError(t("document_not_found"));
          return;
        }
      } else {
        setError(t("unexpected_error"));
      }
    }
  };

  return (
    <Box className="w-full h-full">
      <Box className="px-6 h-full pt-16 lg:px-72">
        <Box className="flex flex-col w-full mb-2">
          <Box className="flex">
            <Box
              as="span"
              className="font-semibold text-xs mb-0.5 text-primary-900"
            >
              {t("document_id_placeholder")}
            </Box>
          </Box>

          <Box className="flex">
            <Input
              size="xs"
              className="border-neutral-300"
              value={documentId}
              placeholder={t("document_id_placeholder") || ""}
              onChange={(e) => setDocumentId(e.target.value)}
            />

            <Box className="ml-auto">
              <Button
                disabled={isDownloading}
                onClick={() => downLoadDocument()}
                className="ml-2"
                size="xs"
              >
                {t("download")}
              </Button>
            </Box>
          </Box>

          {error && (
            <Banner status="danger" className="mt-2">
              {error}
            </Banner>
          )}
        </Box>

        <DocumentSignatures
          documentId={currentDocumentId}
          updateDocumentId={() => setCurrentDocumentId(documentId)}
        />
      </Box>
    </Box>
  );
};

export default Document;
