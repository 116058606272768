export const downloadFileFromBinary = async (blob: Blob, fileName: string) => {
  triggerDownload(blob, fileName);
};

const triggerDownload = (blob: Blob, fileName: string) => {
  // Workaround to give a custom name to the blob file and download it.
  const a = document.createElement("a");
  a.download = fileName;
  a.href = URL.createObjectURL(blob);
  a.click();
};
